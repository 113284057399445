import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`KCD Community on Discord`}</strong>{` is a community of people who want to make
connections, share ideas, and use software to help make the world a better
place. All in an environment that's friendly and respectful (please read and
follow the `}<a parentName="p" {...{
        "href": "/conduct"
      }}>{`Code of Conduct`}</a>{`).`}</p>
    <p><strong parentName="p">{`And you're invited to join.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://kcd.im/discord"
      }}>{`Click here to join the KCD Community on Discord`}</a></p>
    <h2 {...{
      "id": "things-to-do",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#things-to-do",
        "aria-label": "things to do permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Things to do`}</h2>
    <p>{`In addition to general chat, there are lots of ways to learn and grow with
others in the KCD Community on Discord. Here are a few ideas:`}</p>
    <h3 {...{
      "id": "kents-live-streams",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kents-live-streams",
        "aria-label": "kents live streams permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kent's Live Streams`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`💻 Kent Live`}</inlineCode>{` video channel and the accompanying `}<inlineCode parentName="p">{`#💬-kent-live`}</inlineCode>{` chat
channel are where I do my live streams. Most of the time my live streams are me
coding projects or working on open source.`}</p>
    <h3 {...{
      "id": "kents-office-hours",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kents-office-hours",
        "aria-label": "kents office hours permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kent's Office Hours`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`🏫 Kent's Office Hours`}</inlineCode>{` video channel and the `}<inlineCode parentName="p">{`🏫-officehours`}</inlineCode>{` chat channel
are where I host my `}<a parentName="p" {...{
        "href": "/office-hours"
      }}>{`office hours`}</a>{`. This is a time twice a week
where I go live and answer any question you have. We can even share screens.
Really anything you're working on or any general questions you have can be
answered during my office hours.`}</p>
    <h3 {...{
      "id": "kcd-learning-clubs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kcd-learning-clubs",
        "aria-label": "kcd learning clubs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`KCD Learning Clubs`}</h3>
    <p>{`When we learn together, we learn better, and that's the idea behind the
`}<a parentName="p" {...{
        "href": "/clubs"
      }}>{`KCD Learning Clubs`}</a>{`. I definitely recommend taking advantage of these
to enhance your own learning experience with my course material or anything else
you want to learn.`}</p>
    <h2 {...{
      "id": "moderators",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#moderators",
        "aria-label": "moderators permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Moderators`}</h2>
    <p>{`Moderators are responsible for being good examples of the
`}<a parentName="p" {...{
        "href": "/conduct"
      }}>{`Code of Conduct`}</a>{`. They politely encourage others to do so if they
notice slip-ups. Moderators are the gardeners of the Discord. If you're
interested in being a moderator, let me know in the `}<inlineCode parentName="p">{`#📄-moderator-application`}</inlineCode>{`
channel.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      